import React from "react";
import TransferTemplate from "../../../../components/dashboard/wallets/transfer";

type TransferSymbolProps = {
  params: {
    symbol: string;
  };
};

const TransferSymbol = (props: TransferSymbolProps) => {
  return <TransferTemplate symbol={props.params.symbol} />;
};

export default TransferSymbol;
